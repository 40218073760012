import React, { FC, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Routes } from '../constants/Routes';
import Title from '../components/title';
import Image from 'gatsby-image';
import { IFluidObject } from 'gatsby-background-image';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import Lightbox from '../components/Lightbox';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const UNSERE_LOKALE_QUERY = graphql`
  {
    allContentfulUnsereLokale {
      nodes {
        name
        lat
        lng
        adresse
        position
        bilder {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        beschreibung {
          json
        }
      }
    }
  }
`;

type Lokal = {
  id: string;
  name: string;
  beschreibung: any;
  adresse: string;
  bilder: IFluidObject[];
  latlng: [number, number];
  positionOnWebsite: number;
};

const LocationMap: FC<{ position: [number, number]; className: string; popupText: string }> = ({
  position,
  className,
  popupText,
}) => (
  <>
    {typeof window !== 'undefined' && (
      <Map className={className} center={position} zoom={14}>
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>{popupText}</Popup>
        </Marker>
      </Map>
    )}
  </>
);
const UnsereLokalePage: FC = () => {
  const data = useStaticQuery(UNSERE_LOKALE_QUERY).allContentfulUnsereLokale.nodes;
  const lokale: Lokal[] = data.map((l: any) => ({
    name: l.name,
    beschreibung: l.beschreibung,
    bilder: l.bilder.map((b: any) => b.fluid),
    positionOnWebsite: l.position,
    latlng: [l.lat, l.lng],
    adresse: l.adresse,
  }));

  lokale.sort((a, b) => a.positionOnWebsite - b.positionOnWebsite);

  return (
    <Layout location={Routes.PHILOSOPHY}>
      <SEO title="Unsere Lokale" />

      <div className="mt-8">
        <Title className="mb-4 text-center">Unsere Lokale</Title>
        <div className="mt-8">
          {lokale.map((l, i) => (
            <div
              key={l.id}
              className="transition-all transition-250 hover:shadow-xl max-w-6xl rounded overflow-hidden shadow-lg mx-auto mb-12"
            >
              <div className="md:flex">
                <Lightbox
                  className="w-full md:w-1/2"
                  galleryButtonVisible={false}
                  showLeftRightButtons={true}
                  youtubeUrls={[]}
                  images={l.bilder}
                />
                <div className="w-full md:w-1/2 md:flex md:flex-col md:justify-between">
                  <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">{l.name}</div>
                    <p className="text-gray-700 text-base"> {documentToReactComponents(l.beschreibung.json)}</p>
                  </div>
                  <LocationMap
                    className="pointer-events-none w-full h-64 md:h-full z-0 md:pointer-events-auto md:w-4/6 md:mb-4 md:self-end md:mr-4"
                    position={l.latlng}
                    popupText={l.adresse}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default UnsereLokalePage;
